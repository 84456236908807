import {useContext, useEffect} from "react";
import WebsocketContext from "../contexts/WebsocketContext";

//@@@ type=middleware

function PageMiddleware({children})   {
    const {send} = useContext(WebsocketContext);

    useEffect(() => {
        send({subject: "history", content: JSON.stringify({
                type: "page", name: window.location.pathname, timestamp: new Date().getTime()
            })});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [window.location.pathname]);

    return children;
}

export default PageMiddleware;
