
function server(path) {
    if (path.startsWith("/")) path = path.substring(1);
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        return `http://localhost:5000/${path}`;
    }
    return `https://lagalerie.rs/${path}`;
}

module.exports =  {
    server: server,
    graphQL: server("graphql"),
    ws: () => {
        if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
            return "wss://ws.envelink.com/ws";
            return "ws://localhost:8000/ws";
        }
        return "wss://ws.envelink.com/ws";
    },

    languages: [
        {id: 0, short: 'sr', value: "/", name: "Srpski", label: "RS"},
        {id: 1, short: 'en', value: "/home", name: "English", label: "EN"},
    ],

    pagesMenus: [
        {name: "header meni", value: 1},
        //{name: "FAQ meni", value: 2},
        {name: "sakriveni", value: 3}
    ],
    pagesLvl: 1,

    banners: {
        "productright": {
            name: {sr: "Proizvod strana", en: "Product page"},
            description: {sr: "Desni baneri", en: "Banners on the right"}
        },
    },
    cmsEntryPoint: "products.CmsProducts",

    accentColor: "#ef5a35",
    storageImageFolder: server("storage/galerie/slike"),
    storageFileFolder: server("storage/galerie/fajlovi"),

    modules: ["banners","blog","brands","categories","cms","design","groups",
        "lang","pages","products","public","pwa","seo","settings","storage",
        "textEditor","wishlist","live"],

    designTypes: {
        slider: {type: 1, name: "veliki slajder"},
        banners: {type: 2, name: "baneri"},
        products: {type: 3, name: "carousel proizvoda"},
        categories: {type: 5, name: "kategorije u carousel-u"},
        editor: {type: 6, name: "TEXT editor"},
        brands: {type: 7, name: "brendovi u carousel-u"},
        blog: {type: 8, name: "BLOG carousel"},
        cards: {type: 9, name: "text i slika"},
    },

    product: {
        connected: true,
        gallery: true,
        specifications: true,
        youtube: true,
        price: true,
    },
}