
const translations = {
    "currency": {"sr": "RSD", "en": "EUR"},

    'route.publicLayout': {sr: '/', en: '/'},
    'route.cmsLayout': {sr: '/cms', en: '/cms'},
    'route.cmsAuthLayout': {sr: '/auth', en: '/auth'},
    'route.cmsDashboard': {sr: '', en: 'dashboard'},
    'route.cmsLogin': {sr: '', en: 'login'},
    'route.cmsEmail': {sr: 'lozinka', en: 'password'},
    'route.cmsReset': {sr: 'resetuj/:token', en: 'reset/:token'},
    'route.cmsAdministrators': {sr: 'administratori', en: 'administrators'},
    'route.storage': {sr: 'fajlovi', en: 'files'},
    'route.settings': {sr: 'podesavanja', en: 'settings'},
    'route.cmsCategories': {sr: 'kategorije', en: 'categories'},
    'route.cmsFilters': {sr: 'filteri', en: 'filters'},
    'route.cmsPages': {sr: 'stranice', en: 'pages'},
    'route.cmsProducts': {sr: 'proizvodi', en: 'products'},
    'route.cmsProduct': {sr: 'proizvod', en: 'product'},
    'route.cmsBrands': {sr: 'brendovi', en: 'brands'},
    'route.cmsBanners': {sr: 'baneri', en: 'banners'},
    'route.action': {sr: '/akcija', en: '/action'},
    'route.new': {sr: '/novo', en: '/new'},
    'route.popular': {sr: '/popularno', en: '/popular'},
    'route.campaign': {sr: '/kampanja', en: '/campaign'},
    'route.shop': {sr: 'prodavnica/:parent2?/:parent1?/:slug?', en: 'shop/:parent2?/:parent1?/:slug?'},
    'route.shopOptionalPaths': {
        sr: ['prodavnica', 'prodavnica/:slug', 'prodavnica/:parent1/:slug', 'prodavnica/:parent2/:parent1/:slug'],
        en: ['shop', 'shop/:slug', 'shop/:parent1/:slug', 'shop/:parent2/:parent1/:slug']
    },
    'route.product': {sr: 'proizvod/:id/:slug', en: 'product/:id/:slug'},
    'route.productOptionalPaths': {
        sr: ['proizvod/:id/:slug', 'proizvod/:parent1/:id/:slug', 'proizvod/:parent2/:parent1/:id/:slug', 'proizvod/:parent3/:parent2/:parent1/:id/:slug', 'proizvod/:parent4/:parent3/:parent2/:parent1/:id/:slug'],
        en: ['product/:id/:slug', 'product/:parent1/:id/:slug', 'product/:parent2/:parent1/:id/:slug', 'product/:parent3/:parent2/:parent1/:id/:slug', 'product/:parent4/:parent3/:parent2/:parent1/:id/:slug'],
    },
    'route.shopBrand': {sr: 'brend/:brand/:parent2?/:parent1?/:slug?', en: 'brand/:brand/:parent2?/:parent1?/:slug?'},
    'route.shopBrandOptionalPaths': {
        sr: ['brend/:brand', 'brend/:brand/:slug', 'brend/:brand/:parent1/:slug', 'brend/:brand/:parent2/:parent1/:slug'],
        en: ['brand/:brand', 'brand/:brand/:slug', 'brand/:brand/:parent1/:slug', 'brand/:brand/:parent2/:parent1/:slug']
    },
    'route.brands': {sr: 'brendovi', en: 'brands'},
    'route.wishlist': {sr: 'lista-zelja', en: 'wishlist'},
    'route.contact': {sr: 'kontakt', en: 'contact'},
    'route.cmsBlogs': {sr: 'novosti', en: 'blog'},
    'route.blogs': {sr: 'novosti', en: 'blog'},
    'route.blog': {sr: 'vest/:id/:slug', en: 'post/:id/:slug'},
    'route.cmsBlog': {sr: 'vest/:id', en: 'post/:id'},
    'route.cmsCategoriesStats': {sr: 'kategorije/statistika', en: 'categories/statistics'},
    'route.cmsBrandsStats': {sr: 'brendovi/statistika', en: 'brands/statistics'},
    'route.cmsGroups': {sr: 'grupe', en: 'groups'},
    'route.cmsOrders': {sr: 'porudzbine', en: 'orders'},
    'route.checkout': {sr: 'placanje', en: 'checkout'},
    'route.cmsOrderSettings': {sr: 'porudzbine/podesavanja', en: 'orders/settings'},
    'route.cmsOrderCountries': {sr: 'porudzbine/zemlje', en: 'orders/countries'},
    'route.cmsPromoCodes': {sr: 'promo-kodovi', en: 'promo-codes'},
    'route.orderStatus': {sr: 'porudzbina/:code?/:thankYou?', en: 'order/:code?/:thankYou?'},
    'route.cmsOrderCalendar': {sr: 'porudzbina/kalendar', en: 'order/calendar'},
    'route.orderStatusOptionalPaths': {
        sr: ['porudzbina/:code', 'porudzbina/:code/:thankYou'], en: ['order/:code', 'order/:code/:thankYou']
    },
    'route.userAuthLayout': {sr: 'nalog', en: 'account'},
    'route.userLayout': {sr: 'korisnik', en: 'user'},
    'route.userLogin': {sr: '', en: 'login'},
    'route.userRegister': {sr: 'registracija', en: 'register'},
    'route.userDashboard': {sr: '', en: 'dashboard'},
    'route.userEmail': {sr: 'lozinka', en: 'password'},
    'route.userReset': {sr: 'resetuj/:token', en: 'reset/:token'},
    'route.userOrders': {sr: 'porudzbine', en: 'orders'},
    'route.cmsUsers': {sr: 'korisnici', en: 'users'},
    'route.cmsBannersStats': {sr: 'baneri/statistika', en: 'banners/statistics'},
    'route.cmsLive': {sr: 'uzivo', en: 'live'},
    'route.cmsNewsletter': {sr: 'bilten', en: 'newsletter'},

    'route.catchAll': {sr: '*', en: '*'},

    'param.brands': {sr: 'brendovi', en: 'brands'},
    'param.order': {sr: 'sortiraj', en: 'order'},
    'param.filters': {sr: 'filteri', en: 'filters'},

    'ne postoji stranica': {sr: 'Ne postoji stranica.', en: "Page doesn't exists."},
    "nije postavljeno": {"sr": "nije postavljeno", "en": "not set"},
    "ime": {"sr": "Ime", "en": "Name"},
    "prezime": {"sr": "Prezime", "en": "Lastname"},
    "prazna pretraga": {"sr": "Prazna pretraga", "en": "Empty search"},
    "pretraga": {"sr": "Pretraga", "en": "Search"},
    "odaberi": {"sr": "Odaberi", "en": "Select"},
    "kategorije": {"sr": "Kategorije", "en": "Categories"},
    "kategorija": {"sr": "Kategorija", "en": "Category"},
    "naziv": {"sr": "Naziv", "en": "Name"},
    "slika": {"sr": "Slika", "en": "Image"},
    "opis": {"sr": "Opis", "en": "Description"},
    "kratak opis": {"sr": "Kratak opis", "en": "Short description"},
    "sifra": {"sr": "Šifra", "en": "Code"},
    "barkod": {"sr": "Barkod", "en": "Barcode"},
    "popust pocinje": {"sr": "Popust počinje", "en": "Discount starts"},
    "popust se zavrsava": {"sr": "Popust se završava", "en": "Discount ends"},
    "akcija": {"sr": "Akcija", "en": "Action"},
    "novo": {"sr": "Novo", "en": "New"},
    "popularno": {"sr": "Popularno", "en": "Popular"},
    "kolicina": {"sr": "Količina", "en": "Quantity"},
    "cena": {"sr": "Cena", "en": "Price"},
    "cena na popustu": {"sr": "Cena na popustu", "en": "Discount price"},
    "popust cena": {"sr": "Popust cena", "en": "Discount price"},
    "popust": {"sr": "Popust", "en": "Discount"},
    "cena za prikaz": {"sr": "Cena za prikaz", "en": "Display price"},
    "brend": {"sr": "Brend", "en": "Brand"},
    "lista zelja": {"sr": "Lista želja", "en": "Wishlist"},
    "lista zelja je prazna": {"sr": "Lista želja je prazna.", "en": "Wishlist is empty."},
    "strane": {"sr": "Strane", "en": "Pages"},
    "nalog": {"sr": "Nalog", "en": "Account"},
    "naslovna": {"sr": "Naslovna", "en": "Home"},
    "sirina celog ekrana": {"sr": "Širina celog ekrana", "en": "Full screen width"},
    "sve kategorije": {"sr": "Sve kategorije", "en": "All categories"},
    "od": {"sr": "od", "en": "of"},
    "proizvoda": {"sr": "proizvoda", "en": "products"},
    "sortiraj": {"sr": "Sortiraj", "en": "Sort"},
    "po imenu": {"sr": "Po imenu", "en": "By name"},
    "po relevantnosti": {"sr": "Po relevantnosti", "en": "By relevance"},
    "po nazivu": {"sr": "Po nazivu", "en": "By name"},
    "po ceni rastuce": {"sr": "Po ceni rastuće", "en": "By price ascending"},
    "po ceni opadajuce": {"sr": "Po ceni opadajuće", "en": "By price descending"},
    "po pregledima": {"sr": "Po broju pregleda", "en": "By views count"},
    "ponuda istice za": {"sr": "Ponuda ističe za", "en": "Offer expires in"},
    "dan skr": {"sr": "Dan", "en": "Day"},
    "sat skr": {"sr": "Sat", "en": "Hour"},
    "minut skr": {"sr": "Min", "en": "Min"},
    "sekund skr": {"sr": "Sek", "en": "Sec"},
    "prazno": {"sr": "Prazno", "en": "Empty"},
    "podelite": {"sr": "Podelite", "en": "Share"},
    "pregledi": {"sr": "Pregledi", "en": "Views"},
    "poslednje pogledano": {"sr": "Poslednje pogledano", "en": "Last viewed"},
    "adresa": {"sr": "Adresa", "en": "Address"},
    "kontaktirajte nas": {"sr": "Kontaktirajte nas", "en": "Contact us"},
    "kontakt": {"sr": "Kontakt", "en": "Contact"},
    "pratite nas": {"sr": "Pratite nas", "en": "Follow us"},
    "podaci o firmi": {"sr": "Podaci o firmi", "en": "Firm informations"},
    "preuzmite": {"sr": "Preuzmite", "en": "Download"},
    "predmet": {"sr": "Predmet", "en": "Subject"},
    "poslato": {"sr": "Poslato!", "en": "Sent!"},
    "poruka": {"sr": "Poruka", "en": "Message"},
    "blog": {"sr": "Novosti", "en": "blog"},
    "filteri": {"sr": "Filteri", "en": "Filters"},
    "povezani proizvodi": {"sr": "Povezani proizvodi", "en": "Connected products"},
    "povezani proizvodi podnaslov": {"sr": " ", "en": " "},
    "preporuka za kupovinu": {"sr": "Preporuka za kupovinu.", "en": "Recommendation for purchase."},
    "poslednje novosti": {"sr": "Poslednje novosti", "en": "Latest news"},
    "cookies title": {"sr":"Ovaj veb sajt koristi kolačiće", "en":"This website uses cookies"},
    "cookies desc": {
        "sr": "Koristimo kolačiće za personalizaciju sadržaja, pružanje funkcija društvenih medija i analiziranje saobraćaja. Ako nastavite da koristite naše veb-stranice, saglasni ste sa korišćenjem naših kolačića.",
        "en": "We use cookies to personalize content, to provide functions social media and traffic analysis. If you continue to use our website, you agree to the use of our cookies."
    },
    "prihvatam": {"sr": "PRIHVATAM", "en": "ACCEPT"},
    "kategorije statistika": {"sr": "Kategorije (statistika)", "en": "Categories (statistics)"},
    "brendovi statistika": {"sr": "Brendovi (statistika)", "en": "Brands (statistics)"},
    "porudzbine": {"sr": "Porudžbine", "en": "Orders"},
    "ukupno": {"sr": "Ukupno", "en": "Total"},
    "po broju proizvoda": {"sr": "Po broju proizvoda", "en": "By products count"},
    "dodato u listu zelja": {"sr": "Dodato u listu zelja", "en": "Added to wishlist"},
    "korpa": {"sr": "Korpa", "en": "Basket"},
    "dodajte u korpu": {"sr": "Dodajte u korpu", "en": "Add to cart"},
    "dodajte": {"sr": "Dodajte", "en": "Add to cart"},
    "dodato u korpu": {"sr": "Dodato u korpu", "en": "Added to cart"},
    "korpa je prazna": {"sr": "Korpa je prazna", "en": "Cart is empty"},
    "zavrsite porudzbinu": {"sr": "Završite porudžbinu", "en": "Finish order"},
    "dostava": {"sr": "Dostava", "en": "Delivery"},
    "dostava i placanje": {"sr": "Dostava i plaćanje", "en": "Delivery and payment"},
    "dostava zemlje": {"sr": "Dostava (zemlje)", "en": "Delivery (countries)"},
    "dodajte zemlju": {"sr": "Dodajte zemlju", "en": "Add country"},
    "dodajte nacin dostave": {"sr": "Dodajte način dostave", "en": "Add delivery method"},
    "besplatna dostava preko": {"sr": "Besplatna dostava preko", "en": "Free delivery over"},
    "text za dostavu na checkout strani": {"sr": "Text za dostavu na checkout strani", "en": "Checkout page delivery text"},
    "fizicko lice": {"sr": "Fizičko lice", "en": "Individual"},
    "pravno lice": {"sr": "Pravno lice", "en": "Legal"},
    "maticni broj": {"sr": "Matični broj", "en": "Serial number"},
    "kontakt osoba": {"sr": "Kontakt osoba", "en": "Contact person"},
    "dodatni opis": {"sr": "Dodatni opis", "en": "Additional description"},
    "dozvolite porucivanje pravnim licima": {"sr": "Dozvolite porucivanje pravnim licima", "en": "Allow ordering to legal entities"},
    "nacin dostave": {"sr": "Način dostave", "en": "Delivery method"},
    "nacin placanja": {"sr": "Način plaćanja", "en": "Payment method"},
    "u obradi": {"sr": "U obradi", "en": "In process"},
    "primljeno": {"sr": "Primljeno", "en": "Received"},
    "isporuceno": {"sr": "Isporučeno", "en": "Delivered"},
    "otkazano": {"sr": "Otkazano", "en": "Cancelled"},
    "reklamacija": {"sr": "Reklamacija", "en": "Complaint"},
    "promenite status": {"sr": "Promenite status", "en": "Change status"},
    "zelite da promenite status": {"sr": "Želite da promenite status?", "en": "Do you want to change status?"},
    "porucena cena": {"sr": "Poručena cena", "en": "Ordered price"},
    "osnovno": {"sr": "Osnovno", "en": "Informations"},
    "ime i prezime": {"sr": "Ime i prezime", "en": "Full name"},
    "datum": {"sr": "Datum", "en": "Date"},
    "telefon": {"sr": "Telefon", "en": "Phone"},
    "zemlja": {"sr": "Zemlja", "en": "Country"},
    "proizvod vise nije dostupan": {"sr": "proizvod više nije dostupan", "en": "product is removed"},
    "grad": {"sr": "Grad", "en": "City"},
    "artikal": {"sr": "Artikal", "en": "Article"},
    "br": {"sr": "Br", "en": "No"},
    "contact form quote": {"sr": "Kontaktirajte nas", "en": "Contact us"},
    "informacije za porucivanje": {"sr": "Informacije za poručivanje", "en": "Informations"},
    "obavezna polja": {
        "sr": "Polja označena zvezdicom (*) su obavezna.",
        "en": "Fields marked with an asterisk (*) are required."
    },
    "postanski broj": {"sr": "Poštanski broj", "en": "Area code"},
    "dodatna napomena": {"sr": "Dodatna napomena", "en": "Extra message"},
    "unesite promo kod": {"sr": "Unesite promo kod", "en": "Enter promo code"},
    "proverite kod": {"sr": "Proverite kod", "en": "Check code"},
    "poruceno": {"sr": "Poručeno", "en": "Order"},
    "posalji porudzbinu": {"sr": "POŠALJI PORUDŽBINU", "en": "SUBMIT ORDER"},
    "firma": {"sr": "Firma", "en": "Firm"},
    "boja": {"sr": "Boja", "en": "Color"},
    "proizvod strana tekst za porucivanje": {"sr": "Proizvod strana - tekst za porucivanje", "en": "Product page - order text"},
    "tekst ispod dugmeta": {"sr": "tekst ispod dugmeta", "en": "text under button"},
    "uspesno ste porucili": {"sr": "Uspešno ste poručili!", "en": "You have successfully ordered!"},
    "hvala na poverenju": {"sr": "Hvala na poverenju!", "en": "Thank you for your trust!"},
    "informacije": {"sr": "Informacije", "en": "informations"},
    "poslat email za reset lozinke": {
        "sr": "Poslali smo vam email za reset lozinke.",
        "en": "We sent you an email for link reset."
    },
    "nemate porudzbina": {"sr": "Nemate porudžbina", "en": "You don't have any orders"},

    // AUTH
    "ulogujte se": {"sr": "Ulogujte se", "en": "Login"},
    "registrujte se": {"sr": "Registrujte se", "en": "Register"},
    "registrujte nalog": {"sr": "Registrujte nalog", "en": "Register account"},
    "zapamti me": {"sr": "Zapamti me", "en": "Remember me"},
    "unesite vas email": {"sr": "Unesite vaš email", "en": "Enter your email"},
    "lozinka": {"sr": "Lozinka", "en": "Password"},
    "nova lozinka": {"sr": "Nova Lozinka", "en": "New Password"},
    "resetujte lozinku": {"sr": "Resetujte lozinku", "en": "Reset password"},
    "zaboravili ste lozinku": {"sr": "Zaboravili ste lozinku?", "en": "Forgot your password?"},
    "izlogujte se": {"sr": "Izlogujte se", "en": "Logout"},
    "korisnicko ime": {"sr": "Korisničko ime", "en": "Username"},
    "posaljite": {"sr": "Pošaljite", "en": "Send"},
    "saznaj vise": {"sr": "Saznaj više", "en": "Learn more"},

    // CMS LEFT MENI
    "idite na sajt": {"sr": "Idite na sajt", "en": "Go to website"},
    "instaliraj": {"sr": "Instaliraj", "en": "Install"},
    "azuriranje": {"sr": "Ažuriranje", "en": "Updates"},
    'podesavanja': {sr: 'Podešavanja', en: 'Settings'},
    'stranice': {sr: 'Stranice', en: 'Pages'},
    'brendovi': {sr: 'Brendovi', en: 'Brands'},
    'newsletter': {sr: 'Newsletter', en: 'Newsletter'},

    // CMS LISTS
    "detaljnije": {"sr": "Detaljnije", "en": "Details"},
    "obrisite": {"sr": "Obrišite", "en": "Delete"},
    "zelite da obrisete": {"sr": "Želite da obrišete?", "en": "Do you want to delete?"},
    "broj pogresnih logovanja": {"sr": "Broj pogrešnih logovanja.", "en": "Wrong login attempts."},
    "dodajte administratora": {"sr": "Dodajte administratora", "en": "Add administrator"},
    "zatvorite": {"sr": "Zatvorite", "en": "Close"},
    "sacuvajte": {"sr": "Sačuvajte", "en": "Save"},
    "sacuvano": {"sr": "Sačuvano!", "en": "Saved!"},
    "obrisano": {"sr": "Obrisano!", "en": "Deleted!"},
    "prijavite se": {"sr": "Prijavite se", "en": "Subscribe"},
    "unesite": {"sr": "Unesite", "en": "Enter"},
    "prijavljeni ste": {"sr": "Prijavljeni ste", "en": "You are subscribed"},

    // CMS
    "dashboard": {"sr": "Dashboard", "en": "Dashboard"},
    "administratori": {"sr": "Administratori", "en": "Administrators"},
    "fajlovi": {"sr": "Fajlovi", "en": "Files"},
    "prevodi": {"sr": "Prevodi", "en": "Translations"},
    "kontakt informacije": {"sr": "Kontakt informacije", "en": "Contact informations"},
    "socijalne mreze": {"sr": "Socijalne mreže", "en": "Social networks"},
    "popup reklama": {"sr": "Popup reklama", "en": "Popup banner"},
    "aktivirajte": {"sr": "Aktivirajte", "en": "Acrivate"},
    "deaktivirajte": {"sr": "Deaktivirajte", "en": "Deactivate"},
    "aktiviran": {"sr": "Aktiviran", "en": "Acrivated"},
    "deaktiviran": {"sr": "Deaktiviran", "en": "Deactivated"},
    "odaberite sliku": {"sr": "Odaberite sliku", "en": "Select image"},
    "email za porucivanje": {"sr": "Email za porucivanje", "en": "Orders email"},
    "email za porucivanje desc": {
        "sr": "Email na koji stizu obavestenja (vise emailova odvojite zarezom: email1@gmail.com,email2@gmail.com)",
        "en": "Email to which notifications arrive (separate multiple emails with a comma: email1@gmail.com,email2@gmail.com)"
    },
    "email za kontakt": {"sr": "Email za kontakt", "en": "Contact email"},
    "ime firme": {"sr": "Ime firme", "en": "Firm name"},
    "ulica firme": {"sr": "Ulica firme", "en": "Firm street"},
    "grad firme": {"sr": "Grad firme", "en": "Firm city"},
    "telefon firme": {"sr": "Telefon firme", "en": "Firm phone"},
    "email firme": {"sr": "Email firme", "en": "Firm email"},
    "radno vreme": {"sr": "Radno vreme", "en": "Work hours"},
    "mapa": {"sr": "Mapa", "en": "Map"},
    "call centar": {"sr": "Call centar", "en": "Call center"},
    "interfejs": {"sr": "Interfejs", "en": "Interface"},
    "proizvodi": {"sr": "Proizvodi", "en": "Products"},
    "dodajte fajl": {"sr": "Dodajte fajl", "en": "Add File"},
    "kliknite da dodate fajl": {"sr": "Kliknite da dodate fajl", "en": "Click to add file"},
    "prebacite u": {"sr": "Prebacite u folder", "en": "Change folder"},
    "uklonite": {"sr": "uklonite", "en": "remove"},
    "kliknite na sliku da promenite": {
        "sr": "Kliknite na sliku da promenite",
        "en": "Click on the image to change"
    },
    "otvorite sve": {"sr": "Otvorite sve", "en": "Open all"},
    "zatvorite sve": {"sr": "Zatvorite sve", "en": "Close all"},
    "zelite da obrisete kategoriju": {
        "sr": "Želite da obrišete kategoriju?",
        "en": "Do you want to delete the category?"
    },
    "prevucite da promenite raspored": {
        "sr": "Prevucite da promenite raspored.",
        "en": "Drag to change the order."
    },
    "dodajte kategoriju": {"sr": "Dodajte kategoriju", "en": "Add category"},
    "dodajte podkategoriju": {"sr": "Dodajte podkategoriju", "en": "Add subcategory"},
    "dodajte filter": {"sr": "Dodajte filter", "en": "Add filter"},
    "dodajte podfilter": {"sr": "Dodajte podfilter", "en": "Add subfilter"},
    "header meni": {"sr": "Header meni", "en": "header menu"},
    "FAQ meni": {"sr": "FAQ meni", "en": "FAQ menu"},
    "sakriveni": {"sr": "Sakriveni", "en": "Hidden"},
    "strana": {"sr": "Strana", "en": "Page"},
    "strana je deaktivirana": {"sr": "Strana je deaktivirana.", "en": "Page is deactivated."},
    "prebacite u drugi meni": {"sr": "Prebacite u drugi MENI", "en": "Switch to another MENU"},
    "naziv strane": {"sr": "Naziv strane", "en": "Page name"},
    "vrsta strane": {"sr": "Vrsta strane", "en": "Page type"},
    "dodajte stranu": {"sr": "Dodajte stranu", "en": "Add page"},
    "dodajte podstranu": {"sr": "Dodajte podstranu", "en": "Add subpage"},
    "blokovi": {"sr": "Blokovi", "en": "Blocks"},
    "grupa proizvoda": {"sr": "Grupa proizvoda", "en": "Group of products"},
    "dodajte blok": {"sr": "Dodajte blok", "en": "Add block"},
    "veliki slajder": {"sr": "veliki slajder", "en": "big slider"},
    "baneri": {"sr": "Baneri", "en": "Banners"},
    "baneri statistika": {"sr": "Baneri (statistika)", "en": "Banners (statistics)"},
    "carousel proizvoda": {"sr": "carousel proizvoda", "en": "products carousel"},
    "carousel proizvoda (kategorija)": {"sr": "carousel proizvoda  (kategorija)", "en": "products carousel (category)"},
    "kategorije u carousel-u": {"sr": "kategorije u carousel-u", "en": "categories carousel"},
    "TEXT editor": {"sr": "TEXT editor", "en": "TEXT editor"},
    "brendovi u carousel-u": {"sr": "brendovi u carousel-u", "en": "brands carousel"},
    "BLOG carousel": {"sr": "BLOG carousel", "en": "BLOG carousel"},
    "text i slika": {"sr": "Text i slika carousel", "en": "Text and image carousel"},
    "blok je deaktiviran": {"sr": "Blok je deaktiviran", "en": "Block is deactivated"},
    "blok nije poceo": {"sr": "Blok nije počeo", "en": "Block not started"},
    "blok je istekao": {"sr": "Blok je istekao", "en": "Block is expired"},
    "naslov": {"sr": "Naslov", "en": "Title"},
    "u boji": {"sr": "U boji", "en": "In color"},
    "podnaslov": {"sr": "Podnaslov", "en": "Subtitle"},
    "blok pocinje": {"sr": "Blok počinje", "en": "Block starts"},
    "blok se zavrsava": {"sr": "Blok se završava", "en": "Block ends"},
    "visina slajdera na desktopu (broj)": {
        "sr": "Visina slajdera na desktopu (broj)",
        "en": "Desktop slider height (number)"
    },
    "mobilni": {"sr": "MOBILNI", "en": "MOBILE"},
    "opis slike": {"sr": "Opis (alt) slike.", "en": "Image description (alt)."},
    "link slike": {"sr": "Link slike.", "en": "Image link."},
    "prikazuj na mobilnom": {"sr": "Prikazuj na mobilnom", "en": "Show on mobile."},
    "baner je aktiviran": {"sr": "Baner je aktiviran", "en": "Banner is activated"},
    "baner je deaktiviran": {"sr": "Baner je deaktiviran", "en": "Banner is deactivated"},
    "otvaraj u novom prozoru": {"sr": "Klikom otvaraj u novom prozoru.", "en": "On click open in new window."},
    "broj klikova na baner": {"sr": "Broj klikova na baner", "en": "Number of banner clicks"},
    "dodajte baner": {"sr": "Dodajte baner", "en": "Add banner"},
    "baner nije aktiviran i nece se prikazivati": {
        "sr": "Baner nije aktiviran i nece se prikazivati.",
        "en": "The banner is not activated and will not be displayed."
    },
    "broj banera u jednom redu": {"sr": "Broj banera u jednom redu", "en": "Number of banners in a row"},
    "ako je prazno, svi baneri su u jednom redu": {
        "sr": "ako je prazno, svi baneri su u jednom redu",
        "en": "if blank, all banners are in one line"
    },
    "prikazuj kao carousel": {"sr": "Prikazuj kao carousel", "en": "Show as carousel"},
    "broj kategorija u jednom redu": {"sr": "Broj kategorija u jednom redu", "en": "Number of categories in a row"},
    "broj blokova u jednom redu": {"sr": "Broj blokova u jednom redu", "en": "Number of blocks in a row"},
    "aktivirano": {"sr": "Aktivirano", "en": "Activated"},
    "deaktivirano": {"sr": "Deaktivirano", "en": "Deactivated"},
    "rasprodato": {"sr": "Rasprodato", "en": "Sold"},
    "sve": {"sr": "Sve", "en": "All"},
    "status": {"sr": "Status", "en": "Status"},
    "proizvod je rasprodat": {"sr": "Proizvod je rasprodat.", "en": "Product is sold out."},
    "proizvod je deaktiviran": {"sr": "Proizvod je deaktiviran.", "en": "Product is deactivated."},
    "na vrh": {"sr": "Na vrh", "en": "To Top"},
    "dodajte proizvod": {"sr": "Dodajte proizvod", "en": "Add new product"},
    "dodajte proizvode": {"sr": "Dodajte proizvode", "en": "Add new products"},
    "dodajte brend": {"sr": "Dodajte brend", "en": "Add brand"},
    "zelite da promenite folder": {"sr": "Želite da promenite folder?", "en": "Want to change image folder?"},
    "sirina TEXT bloka": {"sr": "Širina TEXT bloka", "en": "TEXT block width"},
    "broj brendova u jednom redu": {"sr": "Broj brendova u jednom redu", "en": "Number of brands in a row"},
    "shop strana": {"sr": "Shop strana", "en": "Shop page"},
    "shop strana desc": {"sr": "Baneri ispod menija", "en": "Banners under the menu"},
    "proizvod strana": {"sr": "Proizvod strana", "en": "Product page"},
    "proizvod strana desc": {"sr": "Desni baneri na proizvod strani", "en": "Banners on the right"},
    "broj proizvoda u jednom redu": {"sr": "Broj proizvoda u jednom redu", "en": "Number of products in a row"},
    "dodajte rucno proizvode u carousel": {
        "sr": "Dodajte ručno proizvode u carousel.",
        "en": "Add products to carousel."
    },
    "selektuj sve": {"sr": "Selektuj sve", "en": "Select all"},
    "prevucite da premestite": {"sr": "Prevucite da premestite", "en": "Drag to move"},
    "unesite tekst": {"sr": "Unesite tekst", "en": "Enter text"},
    "galerija": {"sr": "Galerija", "en": "Gallery"},
    "specifikacija": {"sr": "Specifikacija", "en": "Specifications"},
    "filteri za grupu proizvoda": {"sr": "Filteri za grupu proizvoda", "en": "Group products filters"},
    "dodajte status": {"sr": "Dodajte status", "en": "Add status"},
    "naslov levo tekst desno": {"sr": "Naslov levo, tekst desno", "en": "Title left, text right"},
    "dodajte vest": {"sr": "Dodajte vest", "en": "Add new"},
    "broj vesti u jednom redu": {"sr": "Broj vesti u jednom redu", "en": "Number of posts in a row"},
    "grupe proizvoda": {"sr": "Grupe proizvoda", "en": "Products groups"},
    "kreirano": {"sr": "Kreirano", "en": "Created"},
    "dodajte grupu": {"sr": "Dodajte grupu", "en": "Add group"},
    "dodajte kampanju": {"sr": "Dodajte kampanju", "en": "Add campaign"},
    "kampanje": {"sr": "Kampanje", "en": "Campaigns"},
    "kampanja": {"sr": "Kampanja", "en": "Campaign"},
    "korisnik mora da odabere filter pre porucivanja": {"sr": "Korisnik mora da odabere filter pre porucivanja", "en": "The user must select a filter before ordering"},
    "promo kodovi": {"sr": "Promo kodovi", "en": "Promo codes"},
    "promo kod": {"sr": "Promo kod", "en": "Promo code"},
    "kreirajte promo kod": {"sr": "Kreirajte promo kod", "en": "Create promo code"},
    "broj kodova": {"sr": "Broj kodova", "en": "Number of codes"},
    "koliko puta kod moze da se iskoristi": {"sr": "Koliko puta kod može da se iskoristi (default je 1)", "en": "How many times the code can be used (default is 1)"},
    "isteka koda": {"sr": "isteka koda. Ako je prazno, traje neograničeno.", "en": "code expiration. If empty, it lasts indefinitely."},
    "kod je istekao": {"sr": "Kod je istekao.", "en": "Code is expired."},
    "preostalo": {"sr": "Preostalo", "en": "Left"},
    "istice": {"sr": "Ističe", "en": "Expires"},
    "na snizenju": {"sr": "Na sniženju", "en": "Discount price"},
    "korisnici": {"sr": "Korisnici", "en": "Users"},
    "banovani": {"sr": "Banovani", "en": "Banned"},
    "nije verifikovan": {"sr": "Nije verifikovan", "en": "Not verified"},
    "nalog je banovan": {"sr": "Nalog je banovan.", "en": "Account is baned"},
    "nalog nije verifikovan": {"sr": "Nalog nije verifikovan.", "en": "Account is not verified"},
    "banujte": {"sr": "Banujte", "en": "Ban"},
    "registrovan": {"sr": "Registrovan", "en": "Registered"},
    "ukloni ban": {"sr": "Ukloni ban", "en": "Remove ban"},
    "prethodna": {"sr": "Prethodna", "en": "Previous"},
    "sledeca": {"sr": "Sledeća", "en": "Next"},
    "kalendar": {"sr": "Kalendar", "en": "Calendar"},
    "broj porudzbina": {"sr": "Broj porudžbina", "en": "Orders number"},
    "live": {"sr": "Live", "en": "Live"},
    "trenutni posetioci": {"sr": "Trenutni posetioci", "en": "Current visitors"},
    "nema korisnika": {"sr": "Nema korisnika", "en": "No visitors"},
    "podrska": {"sr": "Podrška", "en": "Support"},
    "dokumentacija": {"sr": "Dokumentacija", "en": "Documentation"},
    "fajl": {"sr": "Fajl", "en": "File"},
    "prikazuj sliku kao popup": {"sr": "Prikazuj sliku kao POPUP", "en": "Prikazuj sliku kao POPUP"},
};

const extend = require(`../websites/${process.env.REACT_APP_WEBSITE}/translations`);
const extended = {...translations, ...extend};
export default extended;