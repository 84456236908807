import {useRef, useState} from "react";
import WebsocketContext from "../contexts/WebsocketContext";
import config from "../../_config";

//@@@ type=wrapper

function WebsocketWrapper({Next, context}) {
    const [socket, setSocket] = useState(null);
    const hooks = useRef({});
    const queue = useRef([]); // QUEUE before socket connection
    const once = useRef({subject: null, pathname: null}); // prevent double message (from MIDDLEWARE and product, category page)

    /*useEffect(() => {
        setInterval(() =>{
            if(socket) {
                send(message({subject: "ping", content: "ping"}))
            }
        }, 3000);
    }, []);*/

    const init = (payload) => {
        if(socket)  return false;
        let liveSocket = new WebSocket(config.ws());
        liveSocket.onclose = function ()    {
            console.log("Disconnected")
            setSocket(null);
            setTimeout(() => {
                init(payload);
            }, 3000);
        }
        liveSocket.onmessage = function (e)  {
            const js = JSON.parse(e.data);
            try {
                js.content = JSON.parse(js.content);
            } catch (e) {

            }
            if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
                console.log("ONMESSAGE", js)
            }
            if(hooks.current[js.subject])   {
                Object.values(hooks.current[js.subject]).map(callback => {
                    if(callback)   callback(js);
                    return null;
                });
            } else {
                console.log(`%cERROR MISSING: no socket hook ${js.subject}`, 'color: orange');
            }
        }
        liveSocket.onopen = function () {
            console.log("Connected")
            liveSocket.send(message(payload));
            setSocket(liveSocket);
            queue.current.map((payload) => {
                liveSocket.send(message(payload));
                return null;
            });
            queue.current = [];
        };
    }

    const message = (payload) => {
        return JSON.stringify(payload);
    }

    const send = (payload) => {
        if(!socket) {
            queue.current.push(payload);
            return false;
        }
        if(once.current.subject === payload.subject && once.current.pathname === window.location.pathname)   {
            return false;
        }
        const mess = message(payload);
        if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
            console.log("SEND", mess);
        }
        once.current = {subject: payload.subject, pathname: window.location.pathname};
        socket.send(mess);
    }

    const hook = (name, file, callback) => {
        if(!hooks.current[name])    {
            hooks.current[name] = {[file]: callback};
            return false;
        }
        hooks.current[name] = {...hooks.current[name], [file]: callback};
    }

    return <WebsocketContext.Provider value={{init, send, hook}}>
        <Next context={context} />
    </WebsocketContext.Provider>
}
export default WebsocketWrapper;

